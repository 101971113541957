import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.editor.setting');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-dizest-editor-setting',
template: templateSource || '',
    styles: [`

/* file: /opt/github/dizest/dev/project/main/build/src/app/portal.dizest.editor.setting/view.scss */
.wiz-content-heading .btn {
  min-width: 76px;
  padding: 8px 16px;
  font-weight: 1000;
  border-radius: 24px;
  margin-left: 10px;
}
.wiz-content-heading input {
  border-radius: 24px;
  text-align: center;
}

.text-blue {
  color: var(--wc-blue) !important;
}

.wiz-content-body {
  display: flex;
  height: 100%;
  background: var(--wc-background);
}
.wiz-content-body .content-main {
  flex: auto;
  height: 100%;
  overflow: auto;
}
@media (max-width: 768px) {
  .wiz-content-body .content-main.m-hidden {
    display: none;
  }
}
.wiz-content-body .content-info {
  width: 640px;
  max-width: 50%;
  background: #fff;
  border-left: 1px solid var(--wc-border);
  border-top: 1px solid var(--wc-border);
}
@media (max-width: 768px) {
  .wiz-content-body .content-info {
    max-width: 100%;
  }
}
.wiz-content-body .content-header {
  display: flex;
  background-color: var(--wc-blue);
  color: #fff;
  width: 100%;
  padding: 8px 12px;
  align-items: center;
}
.wiz-content-body .content-header h1,
.wiz-content-body .content-header h2,
.wiz-content-body .content-header h3 {
  margin: 0;
  padding: 0;
}
.wiz-content-body .content-header .close-btn {
  cursor: pointer;
  font-size: 16px;
}

table td,
table th {
  white-space: nowrap;
}
table tr {
  cursor: pointer;
}

.container {
  padding: 24px 48px;
}
@media (max-width: 768px) {
  .container {
    padding: 24px;
  }
}

.nav-item {
  cursor: pointer;
}

.card-sm {
  min-height: 130px;
}

.kernel-list .link-muted i {
  cursor: pointer;
}
.kernel-list .link-muted i:hover {
  color: var(--wc-blue);
}`],
})
export class PortalDizestEditorSettingComponent implements OnInit {
    @Input() tab: any = {};

    public data: any = { conda: [], setting: {} };
    public mode: string = 'system';

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.loading.show();
        await this.service.init();
        let { data } = await wiz.call("load", { zone: this.tab.dizest.zone });
        this.data.setting = data;
        this.data.conda = await this.conda.list();
        await this.service.render();
        await this.service.loading.hide();
    }

    public async wizOnTabInit() {
        await this.service.render();
    }

    public async wizOnTabHide() {
        await this.service.render();
    }

    public async switchTab(tab: string) {
        this.mode = tab;
        await this.service.render();
    }

    public async request(mode: string, fnname: string, query: any = {}) {
        let dizest = this.tab.dizest;
        const { code, data } = await dizest.api.call(mode, fnname, query);
        return { code, data };
    }

    public conda: any = ((obj: any = {}) => {
        obj.item = { name: "", python: '3.10' };

        obj.iscreate = false;

        obj.list = async () => {
            let { code, data } = await this.request("conda", "list", { dizest: true });
            data = data.sort((a, b) => a.name.localeCompare(b.name));
            if (code == 200) return data;
            return [];
        }

        obj.create = async () => {
            if (!obj.iscreate) {
                obj.iscreate = true;
                obj.item = { name: "", python: '3.10' };
                await this.service.render();
                return;
            }

            let item = obj.item;

            if (!item.name) return;
            if (item.name.length < 3) return this.service.toast.error("Check name");
            if (!/^[a-z0-9]+$/.test(item.name)) return this.service.toast.error("Check name");

            for (let i = 0; i < this.data.conda.length; i++) {
                if (this.data.conda[i].name == item.name) {
                    this.service.toast.error("Env exists");
                    return;
                }
            }

            await this.service.loading.show();
            await this.request("conda", "create", { name: item.name, python: item.python });
            item.name = "";
            this.data.conda = await obj.list();
            obj.iscreate = false;
            await this.service.render();
            await this.service.loading.hide();
        }

        obj.upgrade = async (item: any) => {
            await this.service.loading.show();
            await this.request("conda", "upgrade", { name: item.name });
            this.data.conda = await obj.list();
            await this.service.render();
            await this.service.loading.hide();
        }

        obj.remove = async (item: any) => {
            if (!item.name) return;
            if (item.name.length < 3) return;
            let res = await this.service.alert.show({ message: "Do you really want to remove env? What you've done cannot be undone.", cancel: 'Cancel', action: 'Remove' });
            if (!res) return;

            await this.service.loading.show();
            await this.request("conda", "remove", { name: item.name });
            item.name = "";
            this.data.conda = await obj.list();
            await this.service.render();
            await this.service.loading.hide();
        }

        obj.cancel = async () => {
            obj.iscreate = false;
            await this.service.render();
        }

        return obj;
    })();

    public async update() {
        await wiz.call("update", { data: JSON.stringify(this.data.setting), zone: this.tab.dizest.zone });
        await this.tab.alert.info(`updated system info`, 2000);
    }

    public async uploadLogo() {
        this.data.setting.logo = await this.service.file.read({ type: 'image', accept: 'image/*', width: 180, quality: 1 });
        await this.service.render();
    }

    public async uploadIcon() {
        this.data.setting.icon = await this.service.file.read({ type: 'image', accept: 'image/*', width: 48, quality: 1 });
        await this.service.render();
    }

    public async restart() {
        let res = await this.service.alert.show({ message: "Do you really want to restart server? All working workflow is stop after restarting.", cancel: 'Cancel', action: 'Restart' });
        if (!res) return;
        await this.service.loading.show();
        try {
            await wiz.call("restart", { zone: this.tab.dizest.zone });
        } catch (e) {
        }
        while (true) {
            try {
                let { code } = await wiz.call("status", { zone: this.tab.dizest.zone });
                if (code == 200) break;
            } catch (e) {
            }
            await this.service.render(500);
        }
        await this.service.loading.hide();
    }

}

export default PortalDizestEditorSettingComponent;