import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.access');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-access',
template: templateSource || '',
    styles: [`

/* file: /opt/github/dizest/dev/project/main/build/src/app/page.access/view.scss */
.login-bg {
  background-image: url(/assets/bg.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.logo {
  max-width: 240px;
  margin-bottom: 36px;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.form a {
  font-family: "main-eb";
  position: relative;
  display: inline-block;
  padding: 10px 32px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 24px;
  letter-spacing: 2px;
  cursor: pointer;
}
.form a:hover {
  background: #03e9f4;
  color: #fff !important;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4;
}
.form a span {
  position: absolute;
  display: block;
}

.login-box .etc-box {
  margin-bottom: 30px;
}
.login-box .etc-box label {
  padding: 10px 0;
  color: #03e9f4;
  font-size: 12px;
}
.login-box .etc-box .form-selectgroup-item {
  padding: 0;
}
.login-box .input-box {
  position: relative;
}
.login-box .input-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .input-box input:disabled ~ label,
.login-box .input-box input:focus ~ label,
.login-box .input-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}
.login-box .input-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%, 100% {
    left: 100%;
  }
}
@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%, 100% {
    top: 100%;
  }
}
@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%, 100% {
    right: 100%;
  }
}
@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%, 100% {
    bottom: 100%;
  }
}
.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 2s linear infinite;
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 2s linear infinite;
  animation-delay: 0.5s;
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 2s linear infinite;
  animation-delay: 1s;
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 2s linear infinite;
  animation-delay: 1.5s;
}

input::selection {
  background: #03e9f4;
}
input::-moz-selection {
  background: #03e9f4;
}

.form-selectgroup-label {
  font-family: "main-eb";
}

.form-selectgroup-input {
  background: #fff;
  border: #fff;
  color: var(--wc-text);
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  z-index: 1;
  color: #fff;
  background: #03e9f4;
  border-color: #03e9f4;
}`],
})
export class PageAccessComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public user: any = {
        id: '',
        username: '',
        password: ''
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/");
    }

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "Confirm",
            status: status
        });
    }

    public async login() {
        let user = JSON.parse(JSON.stringify(this.user));
        let { code, data } = await wiz.call("login", user);
        if (code == 200) {
            location.href = "/";
            return;
        }
        await this.alert(data);
    }
}

export default PageAccessComponent;