import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.empty');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-empty',
template: templateSource || '',
    styles: [`

/* file: /opt/github/dizest/dev/project/main/build/src/app/layout.empty/view.scss */
.wiz-layout-view {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}`],
})
export class LayoutEmptyComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        document.title = "DIZEST";
    }
}

export default LayoutEmptyComponent;